.List {
	height: 100%;
	width: 100%;
}

// .lat-form {
// 	height: 100%;
// 	margin: auto;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;

// 	&__container {
// 		width: 100%;
// 		max-width: 375px;
// 		min-height: 667px;
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 		padding: rem(30);
//     position: relative;

// 		// &_send {
// 		// 	background-color: #EDEDED;
// 		// }

// 		// &_success {
// 		// 	background-color: #8ce37dde;
// 		// }

// 		// &_error {
// 		// 	background-color: #e37d7dde;
// 		// }

// 		&_shadow {
// 			box-shadow: 0 0 6px rgb(0 0 0 / 14%);
// 			border-radius: 12px;
// 		}
// 	}

// 	&__sheets {
// 		width: 100%;
// 		overflow: hidden;
// 	}

// }

.header {
	height: 50px;
	width: 100%;
	display: flex;

	justify-content: space-between;
	align-items: center;
	&__center {
		height: 100%;
		// width: 50px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.sheets {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	&__item {
		width: 100%;
		display: flex;
		justify-content: space-between;
    margin-bottom: 10px;
	}

	&__course {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__teachers {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__btn {
		text-decoration: none;
		color: inherit;
		border: 1px solid #000;
	}

	&__list-stu {
		width: 100%;
		overflow: scroll;
	}

	&__send {
		margin-bottom: 15px;

		&_success {

			button {
				background-color: #8ce37dde;

			}
		}

		&_error {
			button {
				background-color: #e37d7dde;

			}
		}
	}
}

