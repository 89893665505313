.notice-lesson-container {
  display: flex;
  justify-content:space-between;
  border: 1px solid grey;
  padding: 5px;
}

.sheets__item-text-info {
  width: 100%;
}

.info-student-item {
  text-wrap: wrap;
}
