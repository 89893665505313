.Students {
	// height: 100%;
	width: 100%;
}

// .lat-form {
// 	height: 100%;
// 	margin: auto;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;

// 	&__container {
// 		width: 100%;
// 		max-width: 375px;
// 		// height: 500px;
// 		max-height: 667px;
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 		padding: rem(30);
// 		// background-color: #ededed;
// 		overflow: hidden;

// 		&_shadow {
// 			box-shadow: 0 0 6px rgb(0 0 0 / 14%);
// 			border-radius: 12px;
// 		}
// 	}

//   &__foo-btn {
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     margin-top: 20px;
//   }
// }

// .Lists {
// 	width: 100%;
// 	overflow: scroll;
// }


.search {
  position: relative;
	margin-top: 10px;
	margin-bottom: 20px;

  input {
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 15px;
    border-radius: 15px;
    // width: 100%;
    font-size: 16px;
    padding-left: 50px;
  }

  svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 17px;
    left: 20px;
    opacity: 0.3;
  }
}

.action {
	width: 28px;
	height: 28px;
	opacity: 0.3;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
}
