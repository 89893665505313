.App {
	// height: 100%;
	// width: 100%;
	// max-width: 375px;
	// min-height: 667px;
	// display: flex;
	// flex-direction: column;
	// align-items: center;
}

#root {
	height: 100%;
	// width: 100%;
	// max-width: 375px;
	// min-height: 667px;
	// display: flex;
	// flex-direction: column;
	// align-items: center;
  // justify-content: center;
}