.modal__wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0,0,0, .2);
  backdrop-filter: blur(10px);
}

.modal__wrapper.open {
  height: 100%;
  display: block;
}

.modal__wrapper.close {
  height: 0;
  display: none;
}

.modal__body {
  // max-width: 300px;
  // margin: auto;
  // background: white;
  // margin-top: 5%;
  // padding: 20px;
  // border-radius: 10px;
  max-height: 100%;
  // overflow-y: scroll;
  height: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
}

.modal__close {
  width: 100%;
  text-align: right;
  margin-top: -5px;
  margin-right: 5px;
  font-size: 40px;
  color: black;
  cursor: pointer;
}

.modal__text {
  margin-bottom: 30px
}

.btn-modal {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
