.lat-navbar{
	margin-top: 20px;
	margin-bottom: 20px;

	&__container {
		display: flex;
    flex-wrap: wrap;
	}

	&__item {
		margin-right: 10px;
    margin-bottom: 15px;
	}
}
