.row {
	width: 100%;
	background: #fff;
	&__container {
		display: flex;
		align-items: center;
		border: 1px solid black;
	}
	&__name {
		flex: 1 0 auto;
		padding: 10px;
		vertical-align: middle;

		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 17px;

		border-left: 1px solid black;
		// border-right: 1px solid black;
	}
	&__check {
		padding: 5px;
	}
	&__number {
		padding: 5px 10px;

		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
	}
	input {
		// height: 20px;
		// width: 20px;
	}
}
